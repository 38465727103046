import React from 'react'
import {Flex, Title2} from './UI'
import styled from 'styled-components'
import Most from '../svg/meestgekozen.svg'
import Appointment from '../svg/zonderafspraak.svg'
import {breakpoints} from '../utils/helper'
import _ from 'lodash'
import Stars from './Stars'
import Price from "./Price";
import Stamp from "./Stamp";

const Container = styled(Flex)`
    width: 236px;
    height: 400px;
    background-color: ${props => props.inverse ? props.theme.colors.white : props.theme.colors.cardGrey};
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: ${props => props.inverse ? props.theme.colors.black : props.theme.colors.white};
    border-radius: 12px;
    margin: 0 12px;
    padding: 36px 18px 22px;
    
    @media ${breakpoints.tablet} {
        margin: 0 12px;
        padding: 36px 18px 22px;
    }
`

const StyledTitle = styled(Title2)`
    text-align: center;
    padding: 0 0 4px;
    font-size: 1.925rem !important;
`

const Features = styled.ul`
    list-style: none;
    padding: 0 20px;
    margin: 12px 0 18px 0;
    list-style-position: outside;
    
    li:before {
        content: "+";
        color: red;
        margin: 0 10px 0 -19px;
        font-size: 0.75rem;
    }
    
    li {
        font-size: 0.75rem;
        line-height: 1.6;
    }
`

const Spacer = styled.div`
    height: 100%;
    flex: 2;
`

const Ribbon = styled.div`
    position: absolute;
    top: 0;
    right: 12px;
`

const Divider = styled.div`
    height: 100%;
    border-left: 1px dashed rgba(216, 216, 216, 0.56);
    width: 2px;
    position: absolute;
`

const Program = ({item, highlight, className, inverse}) => {
    const stars = _.get(item, 'stars')
    const specs = _.get(item, 'specs')
    const stamps = _.get(item, 'stamps')
    const specials = _.get(item, 'special_features', 0)

    const ribbon = () => {
        if (stars === 3) {
            return (<Ribbon><Most/></Ribbon>)
        } else if (stars === 5) {
            return (<Ribbon><Appointment/></Ribbon>)
        }

        return <></>
    }

    return (
        <div className={className}>
            {stars > 4 && <Divider/>}
            <Container highlight={highlight} inverse={inverse}>
                {
                    ribbon()
                }
                <StyledTitle>{_.get(item, 'title')}</StyledTitle>
                <Stars stars={stars}/>
                <Features>
                    {specs.map((item, idx) =>
                        <li
                            key={item}
                            style={(inverse && idx >= specs.length - specials) ? {fontWeight: 'bold', color: 'red'} : {}}
                        >
                            {`${item}`}
                        </li>)
                    }
                </Features>
                <Spacer/>
                <Price inverse {...item}/>
                {stamps > 0 && <Stamp count={stamps} inverse={inverse}/>}
            </Container>
        </div>
    )
}

export default Program
