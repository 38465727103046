import React, { useRef } from 'react'
import { Title} from './UI'
import styled from 'styled-components'
import Program from './Program'
import { breakpoints } from '../utils/helper'
import Flickity from 'react-flickity-component'

const Container = styled.div`
    background-color: #000000;
    display: flex;
    padding: 12px 0 112px;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;

    @media ${breakpoints.tablet} {
        background-color: #000000;
        padding: 78px 0 158px;
    }
    
    .flick {
        position: relative;
    }
    
    .flickity-enabled.is-draggable .flickity-viewport {
        cursor: grab;
    }

    .flickity-enabled {
        outline: none;
    }

    .flickity-page-dots {
        position: absolute;
        width: 100%;
        bottom: -35px;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
        line-height: 1;
        
        @media ${breakpoints.tablet} {
            display: none;
        }
        
        .dot {
            display: inline-block;
            width: 27px;
            height: 4px;
            margin: 0 2px;
            background: #A7A7A7;
            cursor: pointer;
            opacity: 0.75;
            border-radius: 2px;
            
            &.is-selected {
                background: #FF3A00;
            }
        }
    `

const StyledTitle = styled(Title)`
    color: #000000;
    text-align: center;
    margin-bottom: 42px;
    
    @media ${breakpoints.tablet} {
        color: #ffffff;
        margin-bottom: 90px;
    }
`

const ProgramSection = (data) => {
    const carousel = useRef()

    const options = {
        cellSelector: '.card',
        initialIndex: 2,
        accessibility: true,
        prevNextButtons: false,
        contain: false,
        pageDots: true
    }

    return (
        <Container>
            <StyledTitle>Onze wasprogramma's</StyledTitle>
            {typeof window !== 'undefined' &&
                <Flickity className='flick' options={options} ref={carousel} reloadOnUpdate>
                    {data.programs.map((item, i) =>
                        <Program key={`${i}-card`} item={item} className="card" highlight={i === 2} inverse={i === 4}/>
                    )}
                </Flickity>
            }
        </Container>
    )
}


export default ProgramSection
